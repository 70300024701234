import { Link } from 'gatsby'
import React from 'react'

import Divider from '../../../Divider'
import HeroContainer from '../../../Elements/HeroContainer'

import { formatPathURL } from '../../../../utils/sanitization'
import { sortByAlphabetic } from '../../../../utils/array.utils'

import { CardCategoryContainer, CardCategory } from './styles'

const CategoryContent = (props) => {
    const { type, heroBackgroundImage, title, description, categoriesData } = props

    return (
        <>
            <HeroContainer
                backgroundImage={heroBackgroundImage}
                title={title}
                description={description}
            />

            <Divider size={8} orientation="horizontal" />

            <CardCategoryContainer>
                {categoriesData
                    .sort((categoryA, categoryB) =>
                        sortByAlphabetic('asc', categoryA.title, categoryB.title),
                    )
                    .map((category, index) => (
                        <Link
                            className="category-card-item"
                            key={index}
                            to={`/solucoes/${type}/${formatPathURL(category.title)}`}
                        >
                            <CardCategory backgroundImage={category?.background?.publicURL}>
                                <div className="content-card-category">
                                    <h2 class="font-white">{category.title}</h2>
                                </div>
                            </CardCategory>
                        </Link>
                    ))}
            </CardCategoryContainer>

            <Divider size={8} orientation="horizontal" />
        </>
    )
}

export default CategoryContent
