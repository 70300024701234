import styled from 'styled-components'

export const CardCategoryContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .category-card-item {
        flex-grow: 1;
        flex-basis: 25%;
        max-width: 25%;
        margin: 10px 5px;

        min-width: 250px;
    }
`

export const CardCategory = styled.div`
    background-image: ${(props) =>
        `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${props.backgroundImage})`};
    background-position: center;
    width: 100%;
    height: 340px;
    position: relative;

    .content-card-category {
        width: 100%;
        position: absolute;
        bottom: 16px;

        display: flex;
        justify-content: center;

        text-transform: uppercase;
        text-align: center;

        h2 {
            font-weight: bold;
            font-size: 22px;
        }
    }

    transition: all 0.5s ease;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`
