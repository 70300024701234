import styled from 'styled-components'

export const Container = styled.section`
    background: ${(props) => `url(${props.backgroundImage})`};
    height: auto;
    background-position: top center;
    background-attachment: fixed;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    padding: 64px 8px;

    .content-hero {
        height: 100%;
        width: 100%;

        padding: 5px 5vw;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;

        color: #ffffff;
    }

    h1.title-hero {
        font-size: 36px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 4px;
        width: 90%;
        text-align: center;
    }

    .description-hero {
        width: 80%;
        text-align: center;
    }
`
