export const sortByAlphabetic = (order, valueA, valueB) => {
    const valueAFormatted = valueA.trim()
    const valueBFormatted = valueB.trim()

    if (valueAFormatted > valueBFormatted) {
        if (order === 'asc') {
            return 1
        } else {
            return -1
        }
    }
    if (valueBFormatted > valueAFormatted) {
        if (order === 'asc') {
            return -1
        } else {
            return 1
        }
    }
    return 0
}
