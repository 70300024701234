import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../layout'
import SEO from '../../utils/seo'

import CategoryContent from '../../components/Modules/Solutions/CategoryContent'

const SectorPage = () => {
    const { sectorData } = useStaticQuery(graphql`
        query {
            sectorData: allStrapiSetor {
                edges {
                    node {
                        id
                        categories {
                            description
                            id
                            title
                            background {
                                publicURL
                            }
                        }
                        Solucoes {
                            description
                            id
                            title
                            background {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
    `)

    const mainSectorData = sectorData.edges[0].node

    return (
        <Layout>
            <SEO title="Setor" />

            <CategoryContent
                type="setor"
                title={mainSectorData.Solucoes.title}
                description={mainSectorData.Solucoes.description}
                heroBackgroundImage={mainSectorData.Solucoes.background.publicURL}
                categoriesData={mainSectorData.categories}
            />
        </Layout>
    )
}

export default SectorPage
