import React from 'react'

import Divider from '../../Divider'
import { Container } from './styles'

const HeroContainer = (props) => {
    const { backgroundImage, title, description } = props

    return (
        <Container backgroundImage={backgroundImage}>
            <div class="overlay" />

            <div class="content-hero">
                <h1 class="title-hero">{title}</h1>

                <Divider size={4} orientation="horizontal" />

                <p class="description-hero">{description}</p>
            </div>
        </Container>
    )
}

export default HeroContainer
